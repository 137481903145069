import React, { useState } from "react";
import { ArrayInput, AutocompleteInput, Create, CreateButton, DateInput, email, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, SimpleFormIterator, TextInput, useChoicesContext, useCreate, useNotify } from "react-admin";
import { useFormContext } from "react-hook-form";
import Map from "../components/Map";
import { Box, Button, Modal } from "@mui/material";

export const SelectCustomerInput = (props) => {
  const { watch } = useFormContext();
  const customer = watch("customer");
  const address = watch("address");
  const isInBranch = useFormContext().watch("isInBranch");
  const notify = useNotify();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [create] = useCreate();
  const postCustomer = async (data) => {
    try {
      await create(
        "enterprise/customers",
        { data },
        {
          onSuccess: ({ data }) => {
            notify("Creado correctamente", "info");
            handleClose();
          },
          onFailure: (error) => {
            notify(`Error: ${error.message}`, "warning");
          },
        }
      );
    } catch (error) {
      notify(`Error: ${error.message}`, "error");
    }
  };

  return (
    <>
      <ReferenceInput
        label="Cliente"
        source="customer"
        reference="enterprise/customers"
        filter=""
        // filterToQuery={(text) => ({ fullName: [`/${text}/i`] })}
      >
        <AutocompleteCustomer />
      </ReferenceInput>
      <CreateButton variant="text" color="primary" onClick={handleOpen} type="button" label="Crear Nuevo Cliente" />
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: 500, md: 600 },
            maxHeight: "90vh",
          }}
        >
          <Create title={<>&nbsp;- Crear Nuevo Cliente</>}>
            <header style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1rem 1rem 0" }}>
              <h2 style={{ margin: 0 }}>Crear Nuevo Cliente</h2>
              <Button
                variant="text"
                onClick={handleClose}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 40,
                  height: 40,
                  minWidth: 40,
                  minHeight: 40,
                  borderRadius: "50%",
                  fontSize: { xs: "20px", sm: "24px", md: "28px" },
                  transition: "all 0.3s ease-in-out",
                }}
              >
                ✕
              </Button>
            </header>
            <SimpleForm onSubmit={postCustomer}>
              <div style={{ overflow: "auto", position: "relative", maxHeight: "60vh", display: "flex", columnGap: 20, flexWrap: "wrap"}}>
                <TextInput validate={[required()]} source="fullName" label="Nombre Completo" />
                <NumberInput source="phone" label="Teléfono/Celular" />
                <TextInput validate={[email()]} source="email" label="Email" />
                <DateInput label="Fecha de Nacimiento" source="birthdate" />
                <SelectInput
                  label="Género"
                  source="gender"
                  choices={[
                    { id: "hombre", name: "Hombre" },
                    { id: "mujer", name: "Mujer" },
                  ]}
                />
                <ArrayInput source="addresses" label="Direcciones">
                  <SimpleFormIterator>
                    <Map />
                    <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
                      <TextInput validate={[required()]} source="name" label="Nombre" />
                      <TextInput validate={[required()]} source="street" label="Calle" />
                      <TextInput source="house" label="Número de casa" />
                      <TextInput source="reference" label="Referencia" />
                    </div>
                  </SimpleFormIterator>
                </ArrayInput>
              </div>
            </SimpleForm>
          </Create>
        </Box>
      </Modal>
      {!isInBranch && (
        <SelectInput
          source="address"
          label="Dirección"
          choices={customer?.addresses || []}
          disabled={!!!customer}
          optionText={(value) => value?.name ?? value?.street}
          optionValue="_id"
          parse={(value) => {
            return customer?.addresses.find((e) => e?._id === value);
          }}
          format={(value) => value?.name ?? value?.street}
          validate={[required("La dirección es requerida")]}
        />
      )}
      {address && <Map source={"address.coordinates"} initialLatitude={address?.coordinates?.latitude} initialLongitude={address?.coordinates?.longitude} disabled />}
    </>
  );
};

const AutocompleteCustomer = () => {
  const choicesContext = useChoicesContext();
  return (
    <AutocompleteInput
      validate={[required()]}
      source="customer"
      label="Cliente"
      optionText="fullName"
      parse={(value) => {
        return choicesContext.allChoices.find((e) => e._id === value);
      }}
      clearOnBlur={false}
      filterToQuery={(text) => ({
        filter: JSON.stringify({
          $or: [{ fullName: { $regex: text, $options: "i" } }, { phone: { $regex: text, $options: "i" } }],
        }),
      })}
    />
  );
};
