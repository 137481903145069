import React from "react";
import { styled } from "@mui/material/styles";
import {
  Edit as BaseEdit,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  email,
  DateInput,
  SelectInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import Map from "../../components/Map";
const PREFIX = "Edit";

const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const StyledBaseEdit = styled(BaseEdit)({
  [`& .${classes.inlineBlock}`]: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

export const Edit = ({ ...props }) => (
  <StyledBaseEdit {...props}>
    <SimpleForm>
      <TextInput validate={[required()]} source="name" label="Nombre" />
      <Map source="addressInfo" />
      <TextInput
        validate={[required()]}
        source="addressInfo.street"
        label="Calle"
      />
      <TextInput source="addressInfo.phone" label="Teléfono/Celular" />
      <ReferenceArrayInput
        source="services"
        reference="service"
        filter={{
          method: "Ambos,En sucursal",
          enabled: true
        }}
      >
        <SelectArrayInput label="Servicios" />
      </ReferenceArrayInput>

      <BooleanInput source="status" label="Estado" />
    </SimpleForm>
  </StyledBaseEdit>
);
